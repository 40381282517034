<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.appCode')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.appCode',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.appCode') },
                      { max: 100, message: this.$t('field.appCode') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.appCode"
                v-model="queryParam.appCode"
                :placeholder="'请输入' + this.$t('field.appCode')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.appName')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-decorator="[
                  'queryParam.appName',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.appName') },
                      { max: 100, message: this.$t('field.appName') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.appName"
                v-model="queryParam.appName"
                :placeholder="'请输入' + this.$t('field.appName')"
              />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.platformCode')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              </a-select>
              <a-input
                v-decorator="[
                  'queryParam.platformCode',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.platformCode') },
                      { max: 100, message: this.$t('field.platformCode') + '长度小于等于100' },
                    ],
                  },
                ]"
                name="queryParam.platformCode"
                v-model="queryParam.platformCode"
                :placeholder="'请输入' + this.$t('field.platformCode')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-if="showAdvancedSearchCondition">
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.siteCode')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-select :allowClear="true" mode="multiple" v-model="queryParam.siteCode">
                <a-select-option
                  v-for="(item, index) in codeList.siteList"
                  :key="index"
                  :value="item.siteCode"
                >{{ item.siteCode }}-{{ item.siteName }}
                </a-select-option>
              </a-select></a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item
              :label="$t('field.marketplaceCode')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-select :allowClear="true" mode="default" v-model="queryParam.marketplaceCode">
                <a-select-option
                  v-for="(item, index) in codeList.marketplaceList"
                  :key="index"
                  :value="item.marketplaceCode"
                >{{ item.marketplaceCode }}-{{ item.marketplaceName }}
                </a-select-option>
              </a-select></a-form-item>
          </a-col>
          <!--          <a-col :md="8" :sm="24">-->
          <!--            <a-form-item-->
          <!--              :label="$t('field.country')"-->
          <!--              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
          <!--              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"-->
          <!--            >-->
          <!--              <a-input-->
          <!--                v-decorator="[-->
          <!--                  'queryParam.country',-->
          <!--                  {-->
          <!--                    rules: [-->
          <!--                      { required: true, message: '请输入' + this.$t('field.country') },-->
          <!--                      { max: 100, message: this.$t('field.country') + '长度小于等于100' },-->
          <!--                    ],-->
          <!--                  },-->
          <!--                ]"-->
          <!--                name="queryParam.country"-->
          <!--                v-model="queryParam.country"-->
          <!--                :placeholder="'请输入' + this.$t('field.country')"-->
          <!--              />-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
        </a-row>
        <a-row :gutter="24">
          <a-col :md="24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
            >
              <a-button icon="plus" type="primary" @click="$emit('handleAdd')">{{ $t('button.create') }}</a-button>
              <a-divider type="vertical" />
              <a-button icon="search" type="primary" @click="queryData">{{
                $t('button.search')
              }}</a-button
              ><a-divider type="vertical" />
              <a-button style="margin-left: 8px" @click="resetData">{{ $t('button.reset') }}</a-button>
              <a @click="toggleAdvancedSearch" style="margin-left: 8px">
                {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
                <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { listSite } from '@/api/system/site'
import { listMarketplace } from '@/api/system/marketplace'

export default {
  name: 'AppSearch',
  components: {},
  props: {},
  data () {
    return {
      codeList: {
        appCode: [],
        platformCode: [],
        siteList: [],
        marketplaceList: [],
        country: []
      },
      showAdvancedSearchCondition: false,
      queryParam: {}
    }
  },
  created () {},
  mounted () {
    const that = this
    listMarketplace({}).then(res => {
      this.codeList.marketplaceList = res.data
    })
    listSite({}).then(res => {
      that.codeList.siteList = res.data
    })
  },
  methods: {
    toggleAdvancedSearch () {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetData () {
      this.$emit('resetData', this.queryParam)
    },
    queryData () {
      this.$emit('resetData', this.queryParam)
    }
  }
}
</script>
