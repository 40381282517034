<template>
  <a-modal
    width="50%"
    :title="$t('page.app')"
    :visible="appModalShow"
    @ok="handleEditOk"
    @cancel="handleEditCancel"
  >
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <a-form
        :form="form"
        :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
        :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
      >
        <a-form-item
          :label="$t('field.appCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'appCode',
              {
                rules: $rules( [
                  { required: true, message: '请输入' + this.$t('field.appCode') },
                  { max: 100, message: this.$t('field.appCode') + '长度小于等于100' },
                  {type:'checkExists', existIdValue:appData.appId, checkField:'appCode', idFieldName: 'appId',tableName:'app' }
                ]),
              },
            ]"
            name="appData.appCode"
            v-model="appData.appCode"
            :placeholder="'请输入' + this.$t('field.appCode')"
            :maxLength="4"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.appName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'appName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.appName') },
                  { max: 100, message: this.$t('field.appName') + '长度小于等于100' },
                ],
              },
            ]"
            name="appData.appName"
            v-model="appData.appName"
            :placeholder="'请输入' + this.$t('field.appName')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.marketplaceList')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-select
            v-model="appData.marketplaceCodeList"
            mode="multiple"
            @change="onMarketplaceListChanged">
            <a-select-option
              v-for="(item, index) in codeList.marketplaceList"
              :key="index"
              :marketplaceName="item.marketplaceName"
              :marketplaceCode="item.marketplaceCode"
              :marketplaceId="item.marketplaceId"
              :marketplace="item"
              :country="item.country"
              :value="item.marketplaceCode"
            >[{{ item.country }}]{{ item.marketplaceCode }}-{{ item.marketplaceName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('field.siteList')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-select v-model="appData.siteCodeList" mode="multiple" @change="onSiteListChanged">
            <a-select-option
              v-for="(item, index) in codeList.site"
              :key="index"
              :site="item"
              :siteCode="item.siteCode"
              :siteName="item.siteName"
              :siteId="item.siteId"
              :marketplaceName="item.marketplaceName"
              :marketplaceCode="item.marketplaceCode"
              :marketplaceId="item.marketplaceId"
              :country="item.country"
              :value="item.siteCode"
            >[{{ item.country }}-{{ item.marketplaceCode }}-{{ item.marketplaceName }}] {{ item.siteCode
            }}-{{ item.siteName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('field.platformCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'platformCode',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.platformCode') },
                  { max: 100, message: this.$t('field.platformCode') + '长度小于等于100' },
                ],
              },
            ]"
            name="appData.platformCode"
            v-model="appData.platformCode"
            :placeholder="'请输入' + this.$t('field.platformCode')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.platformName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'platformName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.platformName') },
                  { max: 100, message: this.$t('field.platformName') + '长度小于等于100' },
                ],
              },
            ]"
            name="appData.platformName"
            v-model="appData.platformName"
            :placeholder="'请输入' + this.$t('field.platformName')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.appStoreName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'appStoreName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.appStoreName') },
                  { max: 100, message: this.$t('field.appStoreName') + '长度小于等于100' },
                ],
              },
            ]"
            name="appData.appStoreName"
            v-model="appData.appStoreName"
            :placeholder="'请输入' + this.$t('field.appStoreName')"
          />
        </a-form-item>
      </a-form>
    </a-card>
  </a-modal>
</template>

<script>
import { listSite } from '@/api/system/site'
import { listMarketplace } from '@/api/system/marketplace'
import { saveApp } from '@/api/system/app'

export default {
  name: 'EditApp',
  props: {},
  data () {
    return {
      form: this.$form.createForm(this),
      appModalShow: false,
      appData: {},
      codeList: {
        site: [],
        isValid: []
      }
    }
  },
  mounted () {
    const that = this
    listMarketplace({}).then(res => {
      this.codeList.marketplaceList = res.data
    })
    listSite({}).then(res => {
      that.codeList.site = res.data
    })
  },
  methods: {
    createForm () {
      let that = this
      this.$nextTick(() => {
        const formData = {}
        Object.keys(that.form.getFieldsValue()).map(key => (formData[key] = this.appData[key]))
        that.form.setFieldsValue(formData)
        console.log('初始化form', that.form)
      })
    },
    add () {
      this.appData = {}
      this.createForm()
      this.appModalShow = true
    },
    edit (data) {
      this.appData = data
      this.createForm()
      // 弹出编辑页面
      this.appModalShow = true
    },
    onMarketplaceListChanged (value, option) {
      console.debug('onMarketplaceListChanged', value, option)
      option.map((item, index, array) => {
        this.appData.marketplaceList.push(item.data.attrs.marketplace)
      })
    },
    onSiteListChanged (value, option) {
      this.appData.siteCodeList = value
      this.appData.siteList = []
      option.map((item, index, array) => {
        this.appData.siteList.push(item.data.attrs.site)
      })
    },
    handleEditCancel (record) {
      this.appData = {}
      // 弹出编辑页面
      this.appModalShow = false
    },
    handleEditOk (record) {
      const that = this
      this.form.validateFieldsAndScroll((err, values) => {
        console.log('validateFieldsAndScroll', err, values)
        if (err && Object.keys(err).length > 0) {
          this.alertError(err)
          return
        }
        this.appData.needUpdate = this.appData.needUpdate === 'Y'
        Object.assign({ ...that.marketplaceData, ...that.form.getFieldsValue() })
        saveApp(this.appData).then((res) => {
          this.appData = {}
          // 弹出编辑页面
          this.appModalShow = false
          that.$message.success(this.$t('save.entity.app.success'))
          that.$parent.$parent.$refs.table.refresh(true)
        }).catch((res) => {
          that.$refs.table.refresh(true)
          that.$message.success(this.$t('save.entity.app.fail'))
        })
      })
    }
  }
}
</script>
<style>
.ant-select{
  width:100%;
}
</style>
